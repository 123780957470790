import * as React from "react";
import Header from "../components/header.jsx";
import Hero from "../components/hero.jsx";
import Winners from "../components/winners.jsx";
import Footer from "../components/footer.jsx";

const IndexPage = () => {
  return (
    <main>
      <title>Pravila nagradne igre</title>
      <Header />
      <Winners />
      <Footer/>
    </main>
  );
};

export default IndexPage;
