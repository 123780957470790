import React, { useRef, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Axios from "axios";

import "../styles/winners.scss";

const Winners = () => {

    const entries = [
        {
            name: "Aldo Šćulac, Pula",
            prize: "Sedmodnevno obiteljsko ljetovanje u FAMILY HOTELU LIŠANJ 4*"
        },
        {
            name: "Marina Vukelić Džido, Rijeka",
            prize: "PlayStation 5"
        },
        {
            name: "Vesna Mijaljević, Zadar",
            prize: "PlayStation 5"
        },
        {
            name: "Stjepan Kotris, Osijek",
            prize: "Dove poklon paket"
        },
        {
            name: "Ivana Gašparić, Koprivnica",
            prize: "Dove poklon paket"
        },
        {
            name: "Vedrana Buktenica, Split",
            prize: "Dove poklon paket"
        },
        {
            name: "Ivan Sarić, Prugovo",
            prize: "Dove poklon paket"
        },
        {
            name: "Robert Vidović, Rešetari",
            prize: "Dove poklon paket"
        },
        {
            name: "Krunoslava Kregar, Zagreb",
            prize: "Dove poklon paket"
        },
        {
            name: "Mladen Habunek, Radovan",
            prize: "Dove poklon paket"
        },
        {
            name: "Tihana Perković, Dugo Selo",
            prize: "Dove poklon paket"
        },
        {
            name: "Marin Zdilar, Split",
            prize: "Dove poklon paket"
        },
        {
            name: "Boris Klepo, Karlovac",
            prize: "Dove poklon paket"
        },
        {
            name: "Maja Kolenić, Donji Vidovec",
            prize: "Dove poklon paket"
        },
        {
            name: "Igor Maček, Varaždin",
            prize: "Dove poklon paket"
        },
        {
            name: "Davor Obrubić, Zagreb",
            prize: "Dove poklon paket"
        },
        {
            name: "Vilma Kovač, Slatine",
            prize: "Dove poklon paket"
        },
        {
            name: "Ninoslav Opačić, Zagreb",
            prize: "Dove poklon paket"
        },
        {
            name: "Marija Batak, Zagreb",
            prize: "Dove poklon paket"
        },
        {
            name: "Mia Poturica, Jakovlje",
            prize: "Dove poklon paket"
        },
        {
            name: "Vid Zaninović, Hvar",
            prize: "Dove poklon paket"
        },
        {
            name: "Ankica Ercegović, Split",
            prize: "Dove poklon paket"
        },
        {
            name: "Marija Kloss, Vinkovci",
            prize: "Dove poklon paket"
        },
        {
            name: "Marin Periskić, Vinkovci",
            prize: "Dove poklon paket"
        },
        {
            name: "Davorka Tojčić, Zagreb",
            prize: "Dove poklon paket"
        },
        {
            name: "Petar Pavošević, Osijek",
            prize: "Dove poklon paket"
        },
        {
            name: "Ivan Pleić, Koprivnica",
            prize: "Dove poklon paket"
        },
        {
            name: "Renata Kirin, Jastrebarsko",
            prize: "Dove poklon paket"
        },
        {
            name: "Ivana Habek, Ludbreg",
            prize: "Dove poklon paket"
        },
        {
            name: "Tomislav Tupek-Petrinjak, Sesvete",
            prize: "Dove poklon paket"
        },
        {
            name: "Mateja Bel, Čakovec",
            prize: "Dove poklon paket"
        },
        {
            name: "Zlatko Varošanec, Čakovec",
            prize: "Dove poklon paket"
        },
        {
            name: "Marijana Plazibat, Solin",
            prize: "Dove poklon paket"
        },
    ]
	return (
		<section className="winners">
            <h1>DOBITNICI NAGRADNE IGRE POVODOM DANA OČEVA!</h1>
            <p>Dana 31.03.2022. završena je Dove nagradna igra povodom Dana očeva, a sve sretne dobitnike možete pronaći ovdje:</p>
			<div className="form_container">
				<StaticImage
					src="../images/gel.png"
					alt="gel"
					placeholder="transparent"
					className="product one"
					quality={100}
				/>
				<StaticImage
					src="../images/sprej.png"
					alt="sprej"
					placeholder="transparent"
					className="product two"
					quality={100}
				/>
				<StaticImage
					src="../images/rolon.png"
					alt="stick"
					placeholder="transparent"
					className="product three"
					quality={100}
				/>
				<StaticImage
					src="../images/stick.png"
					alt="rolon"
					placeholder="transparent"
					className="product four"
					quality={100}
				/>
                <div className="table--container">
                    <table >
                        <tr>
                            <td>
                                <h2>DOBITNIK</h2>
                            </td>
                            <td>
                                <h2>NAGRADA</h2>
                            </td>
                        </tr>
                        {entries.map((winner)=>{
                            return <tr>
                                <td>{winner.name}</td>
                                <td>{winner.prize}</td>
                            </tr>
                        })}
                    </table>
                </div>
			</div>
		</section>
	);
};

export default Winners;
